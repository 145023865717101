import { Component, Input } from '@angular/core';
import { FooterComponent, FooterComponentData } from '../footer.model';

@Component({
  selector: 'app-mobi7-footer',
  templateUrl: './mobi7-footer.component.html',
  styleUrls: ['./mobi7-footer.component.scss'],
})
export class Mobi7FooterComponent implements FooterComponent {
  @Input() data: FooterComponentData;
}
