import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { EVENTS_CONFIG } from '@app-core/constants/constants';
import { DataService } from '@app-core/services/data/data.service';
import { TOP_STREAK_IMAGE } from '@app-driver-management/common/driver-management.constants';
import { EventStreakParams, EventStreakResponse, Streak, UnitMetrics } from '@app-driver-management/common/driver-management.model';
import { DriverManagementService } from '@app-driver-management/services/driver-management.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-top-event-streak',
  templateUrl: './top-event-streak.component.html',
  styleUrls: ['./top-event-streak.component.scss'],
})
export class TopEventStreakComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public startDate: string;
  @Input()
  public endDate: string;
  @Input()
  public driverId: string;
  @Input()
  public fleetId: string;
  @Input()
  public currentTheme: string = 'light';
  @Input()
  public showTitle: boolean = true;

  @Output() areStreaksInsufficient = new EventEmitter<boolean>();

  public topStreaks: { title: string; distance: number }[] = [];
  public otherStreaks: { title: string; distance: number }[] = [];

  public currentMetricUnit: string;

  public loader: boolean = false;
  public showChart: boolean = false;
  public topStreakImage: string = TOP_STREAK_IMAGE;
  public unitMetricsEnum = UnitMetrics;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    public translate: TranslateService,
    public dataService: DataService,
    private driverManagementService: DriverManagementService
  ) {}

  public ngOnInit(): void {
    this.dataService._currentMetricUnit.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      this.currentMetricUnit = value;
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.startDate && changes.startDate.currentValue) ||
      (changes.endDate && changes.endDate.currentValue) ||
      (changes.driverId && changes.driverId.currentValue)
    ) {
      if (this.driverId && this.startDate && this.endDate) {
        this.getEventStreaks();
      }
    }
  }

  private getEventStreaks(): void {
    this.loader = true;
    const params: EventStreakParams = {
      startDate: this.startDate,
      endDate: this.endDate,
      fleetId: this.fleetId,
      driverId: this.driverId,
    };
    this.driverManagementService
      .getDriverEventStreak(params)
      .pipe(
        finalize(() => {
          this.loader = false;
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        (res: EventStreakResponse) => {
          const streaks = res.streaks;
          const combinedEventsList = this.dataService.modifyFleeEvents(true);
          const combinedEventsConfig = { ...EVENTS_CONFIG, ...this.dataService.transformObject(combinedEventsList) };
          const eventsToShow = Object.keys({ ...EVENTS_CONFIG, ...combinedEventsConfig });

          const sortedArray = Object.entries(streaks)
            .filter(([eventType, value]: [string, Streak]) => eventsToShow.includes(eventType) && value.longestStreak > 0)
            .sort((a, b) => b[1].longestStreak - a[1].longestStreak)
            .map(([key, value]) => ({ title: combinedEventsConfig[key].label, distance: value.longestStreak }));
          this.topStreaks = sortedArray.slice(0, 3);
          this.otherStreaks = sortedArray.slice(3);
        },
        () => {
          this.areStreaksInsufficient.emit(true);
        }
      );
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
