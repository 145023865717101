export const ALL_DRIVER_FOR_FLEET_COLUMNS = ['accountStatus', 'driverImages', 'driverName', 'driverId', 'email', 'actions'];

export const DRIVERS_LIST_COLUMNS = (isFaceIdEnabled) => [
  ...(isFaceIdEnabled ? ['driverImages'] : ''),
  'driverId',
  'driverName',
  'email',
  'tags',
  'accountStatus',
  'actions',
];

export const ALL_DRIVER_FOR_FLEET_COLUMNS_USERTYPE_EMAIL_FALSE = (isFaceIdEnabled) => [
  ...(isFaceIdEnabled ? ['driverImages'] : ''),
  'driverName',
  'driverId',
  'email',
  'actions',
];
export const EXTERNAL_DRIVER_LIST_COLUMNS = ['driverName', 'driverId', 'actions'];

export const AI_RECOGNIZED_EVENTS_COLUMNS = ['recognized-events', 'average-action', 'total-actions'];

export const DRIVER_TABLE_PAGE_SIZE = 10;

export const DRIVER_TYPE_LIST = [
  {
    label: 'Manual Addition',
    value: 'manualAddition',
  },
  {
    label: 'External Source',
    value: 'externalSource',
  },
];
export const DRIVERS_PAGE_FILTER_OPTIONS = [
  { Label: 'All', value: 'ALL' },
  { Label: 'Driver', value: 'DRIVER' },
  { Label: 'Installer', value: 'INSTALLER' },
];
export const PAGINATE_SIZES = [5, 10, 15, 20];

export const BOOKMARKED_VIDEOS_TABLE_COLUMNS = [
  'eventType',
  'coachingInitiatedBy',
  'coachingCompletedBy',
  'eventTime',
  'driverName',
  'status',
  'actions',
];

export const CHALLENGE_INCIDENTS_TABLE_COLUMNS = ['eventType', 'challengeResolvedBy', 'eventTime', 'driverName', 'status', 'actions'];

export const DRIVER_PANIC_BUTTON_TABLE_COLUMNS = ['requestTimestamp', 'assetId', 'actions'];

export const BOOKMARKED_PANIC_BUTTON_TABLE_COLUMNS = ['timestamp', 'driverName', 'actions', 'status'];

export const DRIVERID_INPUT_PATTERN = '^(?!.*[_-]{2,})[a-zA-Z0-9_-]*[a-zA-Z0-9][a-zA-Z0-9_-]*$';

export enum DriverType {
  manualAddition = 'manualAddition',
  externalSource = 'externalSource',
}

export enum ManageAction {
  Add = 'add',
  Edit = 'edit',
}

export const AIRecognizedEventTypesMapping = [
  {
    eventType: 'Forward-Collision-Audio-Warning-Heeded',
    eventLabel: 'Forward Collision Warning',
  },
  {
    eventType: 'Tail-Gating-Warning-Heeded',
    eventLabel: 'Tailgating Alerts',
  },
  {
    eventType: 'Traffic-Speed-Sign-Warning-Heeded',
    eventLabel: 'Speeding Alerts',
  },
];

export const EVENT_TYPE_STATS_MAP = {
  'Traffic-Speed-Sign-Warning-Heeded': 'Traffic-Speed-Violated',
  'Tail-Gating-Warning-Heeded': 'Tail-Gating-Detected',
  'Forward-Collision-Audio-Warning-Heeded': 'Forward-Collision-Warning',
};

export const TOP_STREAK_IMAGE = '/assets/common/cup.png';
export const NO_DATA_IMAGE = '/assets/common/vehicle.png';

export const IMG_PLACEHOLDER_URL = '/assets/common/image-placeholder.svg';

export const BATCH_ADDITION_IMG = '/assets/common/batch-addition.svg';

export const PAGE_OPTION = [10, 20, 30, 50];

export const IMG_PLACEHOLDER_COUNT = 5;

export const TOTAL_UPLOAD_COUNT = 5;

export enum DriverAdditionStatus {
  PartialSuccess = 'partialSuccess',
  Success = 'success',
  Failure = 'failure',
  Error = 'error',
}
export enum DriverStatus {
  CONFIRMED = 'CONFIRMED',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
}
