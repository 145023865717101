import { KeyValuePair, MuteEventDurationOption } from '@app-assets/models/assets.model';
import { ClientConfig, DevicePrimaryKey } from '@config/config.base';

export const ASSET_LIST_COLUMNS = (clientConfig: ClientConfig): string[] => {
  return ['assetId', 'assetName', 'tags', clientConfig.devicePrimaryKey, 'dutyType', 'defaultDriver', 'actions', 'configurations'];
};

export const ASSET_FILTER_TYPES = (clientConfig: ClientConfig): { key: string; label: string }[] => {
  const commonFilterTypes = [
    {
      key: 'assetIdFilter',
      label: 'Asset',
    },
    {
      key: 'filterByTags',
      label: 'Tags',
    },
  ];
  if (clientConfig.devicePrimaryKey === DevicePrimaryKey.DeviceId) {
    return [
      ...commonFilterTypes,
      {
        key: 'deviceIdFilter',
        label: 'Device ID',
      },
    ];
  }
  return [
    ...commonFilterTypes,
    {
      key: 'serialNumberFilter',
      label: 'Serial No.',
    },
  ];
};

export const FLEETHEALTH_FILTER_TYPES = (): { key: string; label: string }[] => {
  const commonFilterTypes = [
    {
      key: 'assetIdFilter',
      label: 'Asset ID',
    },
  ];
  return [
    ...commonFilterTypes,
    {
      key: 'deviceIdFilter',
      label: 'Device ID',
    },
  ];
};

export const CAMERA_ONLINE_STATUS_FILTER_TYPES = (): { key: string; label: string }[] => {
  const commonFilterTypes = [
    {
      key: 'assetIdFilter',
      label: 'Asset',
    },
    {
      key: 'daysInactiveFilter',
      label: 'Days Inactive',
    },
  ];
  return [
    ...commonFilterTypes,
    {
      key: 'deviceIdFilter',
      label: 'Device ID',
    },
  ];
};

export const ASSET_LEGACY_LIST_COLUMNS: string[] = ['assetId', 'dutyType', 'actions'];

export const DEFAULT_DUTY_TYPE = 'heavy';
export const CURRENT_ISSUES_BAR_DEFAULT_COLOR = '#a8d3f0';
export const CURRENT_ISSUES_BAR_CLICKED_COLOR = '#126399';
export const DEFAULT_LIGHT_THEME_TEXT_COLOR = '#333333';
export const DEFAULT_LIGHT_THEME_BORDER_COLOR = '#EEEEEE';
export const DEFAULT_DARK_THEME_TEXT_COLOR = '#EEEEEE';
export const DEFAULT_DARK_THEME_BORDER_COLOR = '#666666';
export const LIVE_DEVICE_LIST_LIMIT: number = 100;
export const LIVE_DEVICE_LIST_TABLE_DEFAULT_HEIGHT: string = '200px';
export const DIAGNOSTICS_START_DATE = '2024-03-01T00:00:00.000Z';

export const DEVICE_TYPES_FOR_SLOTS = ['jimi-jc450', 'mitac-evo-k265'];

export const REMOTE_ACTION_LIST = [
  {
    value: 'formatSDCard',
    label: 'Format SD Card',
    type: 'remoteAction',
  },
  {
    value: 'reboot',
    label: 'Reboot Device',
    type: 'remoteAction',
  },
];

export const SLOT_LIST = [
  {
    value: 1,
    label: 'Slot 1',
  },
  {
    value: 2,
    label: 'Slot 2',
  },
];

export const STOP_RECORDING_EXPIRY_OPTIONS = [
  {
    value: 1,
    label: '1 day',
  },
  {
    value: 3,
    label: '3 days',
  },
  {
    value: 7,
    label: '1 week',
  },
  {
    value: 14,
    label: '2 weeks',
  },
];

export const ASSETID_ALLOWED_CHARACTERS = '([A-Za-z0-9-_]+)';

export const ASSETNAME_ALLOWED_CHARACTERS = '^(?=.*[a-zA-Z0-9])[ -~]*$';

export const ASSETNAME_ALLOWED_MAX_LENGTH = 255;

export const AVAILABLE_DUTY_TYPES = [
  {
    label: 'Heavy',
    value: 'heavy',
  },
  {
    label: 'Medium',
    value: 'medium',
  },
  {
    label: 'Light',
    value: 'light',
  },
];

export const CSV_MIME_TYPE = [
  'text/plain',
  'text/x-csv',
  'application/vnd.ms-excel',
  'application/csv',
  'application/x-csv',
  'text/csv',
  'text/comma-separated-values',
  'text/x-comma-separated-values',
  'text/tab-separated-values',
];

export const DEVICES_LIST_COLUMNS: string[] = ['deviceId', 'actions'];

export const CAMERA_EVENT_LIST_TABLE: string[] = ['deviceId', 'assetId', 'actions', 'timestamp'];
export const HEALTH_EVENT_LIST_TABLE: string[] = ['deviceId', 'assetId', 'timestamp'];
export const LIVE_DEVICE_LIST_TABLE: string[] = ['deviceid', 'assetid', 'timestampUTC', 'daysInactive'];
export const HEALTH_CHECK_TABLE_PAGE_SIZE: number = 10;

export const MDVR_AVAILBLE_VIEWS = [
  {
    label: 'Left Blind Spot',
    value: 'LEFT_BLIND_SPOT',
  },
  {
    label: 'Right Blind Spot',
    value: 'RIGHT_BLIND_SPOT',
  },
  {
    label: 'Front Blind Spot',
    value: 'FRONT_BLIND_SPOT',
  },
  {
    label: 'Rear Blind Spot',
    value: 'REAR_BLIND_SPOT',
  },
  {
    label: 'Left Side Camera',
    value: 'LEFT_SIDE_CAMERA',
  },
  {
    label: 'Right Side Camera',
    value: 'RIGHT_SIDE_CAMERA',
  },
];

export const INSTALLATION_TROUBLE_SHOOTED_ISSUES = [
  {
    label: 'Ignition Turned  On',
    value: 'IgnitionON',
  },
  {
    label: 'Network Connected',
    value: 'NetworkConnected',
  },
  {
    label: 'Trip Started',
    value: 'TripStarted',
  },
  {
    label: 'Panic Button Pressed',
    value: 'CustomTriggered',
  },
  {
    label: 'Location Data Synced',
    value: 'GPSSynced',
  },
  {
    label: 'SIM/APN Changed',
    value: 'SIMStateChanged',
  },
  {
    label: 'Video Writing Failed',
    value: 'DVRWriterException',
  },
  {
    label: 'Trip Recording Disabled',
    value: 'TripTemporarilyDisabled',
  },
  {
    label: 'Camera Sensor Issue',
    value: 'CameraSensorReboot',
  },
  {
    label: 'Driver Camera Lens Fixed',
    value: 'DriverdashcamLensCoveredFixed',
  },
  {
    label: 'Road Camera Lens Fixed',
    value: 'RoaddashcamLensCoveredFixed',
  },
  {
    label: 'Road Camera Lens Obstructed',
    value: 'RoaddashcamLensCovered',
  },
  {
    label: 'Driver Camera Lens Obstructed',
    value: 'DriverdashcamLensCovered',
  },
  {
    label: 'Power Cut Issue',
    value: 'PowerCutAlert',
  },
  {
    label: 'Ignition Turned Off',
    value: 'IgnitionOFF',
  },
  {
    label: 'Trip Ended',
    value: 'TripEnded',
  },
  {
    label: 'Unexpected Reboot',
    value: 'Reboot',
  },
];

export const tabQueryParamNames: { [key: string]: string[] } = {
  overview: [],
  manage: [],
  diagnostics: ['cameraevent', 'healthevent', 'cameraonlinestatus'],
  devices: [],
};

export const HEALTH_EVENT_LABLES = {
  PowerCutAlert: {
    label: 'Power Cut Alert',
  },
  DeviceOffline: {
    label: 'Device Offline',
  },
  UnprovisionedDeviceDetected: {
    label: 'Incorrectly Provisioned Devices',
  },
  SDCardNeedFormatting: {
    label: 'SD Card Needs Formatting',
  },
  ExternalStorageAvailable: {
    label: 'SD Card Unavailable',
  },
};

export const CAMERA_EVENT_LABLES = {
  BadMountingRoadCamera: {
    label: 'Incorrect Road Camera Mounting',
  },
  BadMountingDriverCamera: {
    label: 'Incorrect Driver Camera Mounting',
  },
  RoadCameraLensCovered: {
    label: 'Road Camera Lens Obstructed',
  },
  DriverCameraLensCovered: {
    label: 'Driver Camera Lens Obstructed',
  },
  BadMountingInertialSensor: {
    label: 'Incorrect Camera Position',
  },
};

export const DAYS_INACTIVE_OPTIONS = [
  {
    label: '0 Days',
    value: 'zeroDays',
  },
  {
    label: '1 - 5 Days',
    value: 'oneToFiveDays',
  },
  {
    label: '6 - 10 Days',
    value: 'sixToTenDays',
  },
  {
    label: '> 10 Days',
    value: 'moreThanTenDays',
  },
];

export const BAD_CAMERA_MOUNTING_MUTE_EVENT_DURATIONS: MuteEventDurationOption[] = [
  { noOfDays: 1, buttonLabel: '24 Hrs', group: 1 },
  { noOfDays: 3, buttonLabel: '3 Days', group: 1 },
  { noOfDays: 7, buttonLabel: '7 Days', group: 2 },
  { noOfDays: 30, buttonLabel: '1 month', group: 2 },
  { noOfDays: 90, buttonLabel: '3 months', group: 3 },
  { noOfDays: -1, buttonLabel: 'Always', modelLabel: 'Permanently', group: 3 },
];

export const BAD_CAMERA_MOUNTING_TABLE: string[] = ['timestamp', 'eventMedia', 'deviceId', 'assetId', 'eventHealth', 'actions'];

export const BAD_CAMERA_MOUNTING_TABLE_TYPES: KeyValuePair[] = [
  {
    label: 'Active events',
    value: 'activeEvents',
  },
  {
    label: 'Muted events',
    value: 'mutedEvents',
  },
];

export const NO_EVENTS_IMAGE = 'assets/common/empty-container-removebg-preview.png';

export const INSTALLATION_REPORT_EXT_CAM_HEADERS: string[] = ['camera', 'name', 'resolution'];
