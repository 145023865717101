import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { DataService } from '@app-core/services/data/data.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject, forkJoin } from 'rxjs';
import { DriverManagementService } from '@app-driver-management/services/driver-management.service';
import { AIEventStats, DriverStatsResponse } from '@app-driver-management/common/driver-management.model';
import { AIRecognizedEventTypesMapping, EVENT_TYPE_STATS_MAP } from '@app-driver-management/common/driver-management.constants';
import { DriverStatsParams } from '@app-core/models/core.model';
import { HomeService } from '@app-home/services/home.service';

@Component({
  selector: 'app-driver-response-rate',
  templateUrl: './driver-response-rate.component.html',
  styleUrls: ['./driver-response-rate.component.scss'],
})
export class DriverResponseRateComponent implements OnDestroy, OnInit, OnChanges, AfterViewInit {
  @ViewChild('paginator', { static: true })
  public paginator: MatPaginator;

  @Input()
  public startDate: string;
  @Input()
  public endDate: string;
  @Input()
  public driverId: string;
  @Input()
  public fleetId: string;
  @Input()
  layoutClass: string = 'driver-class';

  @Output() hideResponseRate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() statsData = new EventEmitter();

  public loader: boolean = false;

  private aiRecognizedList: AIEventStats[];
  private aiRecognizedEventLabels = AIRecognizedEventTypesMapping;

  public totalResponseRate: {
    totalDriverResponseRate: number;
    totalReactedEvents: number;
    totalAIDetected: number;
  } = {
    totalDriverResponseRate: 0,
    totalReactedEvents: 0,
    totalAIDetected: 0,
  };

  public AIEvents: AIEventStats[];
  public currentMetricUnit: string;
  private stats: { [key: string]: number };

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public dataService: DataService,
    private driverManagementService: DriverManagementService,
    private homeService: HomeService
  ) {}

  public ngOnInit(): void {
    this.dataService._currentMetricUnit.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      this.currentMetricUnit = value;
    });
  }

  public ngAfterViewInit(): void {
    this.dataService._currentMetricUnit.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      this.currentMetricUnit = value;
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (
      (changes?.startDate && changes.startDate?.currentValue) ||
      (changes?.endDate && changes.endDate?.currentValue) ||
      (changes?.driverId && changes.driverId.currentValue)
    ) {
      if (this.driverId && this.startDate && this.endDate) {
        this.getData();
      }
    }
  }

  private getData() {
    this.loader = true;
    const driverStatsParams = new DriverStatsParams({
      fleetId: this.fleetId,
      driverId: this.driverId,
      startDate: this.startDate,
      endDate: this.endDate,
      includeEventDiff: true,
      unit: this.currentMetricUnit === 'Miles' ? 'mi' : 'km',
    });

    const recognizedEventsParams = {
      startDate: this.startDate,
      endDate: this.endDate,
      fleetId: this.fleetId,
      driverId: this.driverId,
    };

    const stats$ = this.homeService.getDriverStats(driverStatsParams);
    const recognizedEvents$ = this.driverManagementService.getAIRecognizedEvents(recognizedEventsParams);

    forkJoin([stats$, recognizedEvents$])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.loader = false;
        })
      )
      .subscribe(
        ([statsResponse, recognizedEventsResponse]) => {
          this.processDriverStats(statsResponse);
          this.processRecognizedEvents(recognizedEventsResponse);
        },
        () => {
          this.hideResponseRate.emit(true);
        }
      );
  }

  private processDriverStats(statsResponse: DriverStatsResponse): void {
    const { aggregate: { eventCount = {}, ...otherDriverDetails } = {} } = statsResponse;
    this.stats = eventCount;
    this.statsData.emit({ ...otherDriverDetails });
  }

  private mapEventWithLabel(event) {
    const foundEvent = this.aiRecognizedEventLabels.find((item) => item.eventType === event.eventType);
    return {
      ...event,
      eventLabel: foundEvent ? foundEvent.eventLabel : event.eventType,
    };
  }

  private updateEventWithStats(event) {
    const relatedStatKey = EVENT_TYPE_STATS_MAP[event.eventType];
    if (!relatedStatKey) return event;

    const violationCount = this.stats[relatedStatKey] || 0;
    const totalAIEvents = event.totalEvents || 0;

    return {
      ...event,
      aiDetected: violationCount + totalAIEvents,
      driverResponseRate: (totalAIEvents / (violationCount + totalAIEvents)) * 100 || 0,
      driverReactedEvents: totalAIEvents,
    };
  }

  private processRecognizedEvents(recognizedEventsResponse): void {
    this.aiRecognizedList = recognizedEventsResponse.stats;

    const filteredDriverList = this.aiRecognizedList.map((event) => this.mapEventWithLabel(event));

    this.AIEvents = filteredDriverList.map((event) => this.updateEventWithStats(event));

    this.calculateTotalResponseRate(this.AIEvents);
  }

  private calculateTotalResponseRate(processedEvents) {
    const totalReactedEvents = processedEvents.reduce((sum, event) => sum + (event.driverReactedEvents || 0), 0);

    const totalAIDetected = processedEvents.reduce((sum, event) => sum + (event.aiDetected || 0), 0);

    const totalDriverResponseRate = totalAIDetected > 0 ? (totalReactedEvents / totalAIDetected) * 100 : 0;

    this.totalResponseRate = {
      totalAIDetected,
      totalDriverResponseRate,
      totalReactedEvents,
    };

    if (totalDriverResponseRate === 0) {
      this.hideResponseRate.emit(true);
    } else {
      this.hideResponseRate.emit(false);
    }
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
